import React from 'react';

function App() {
  return (
    <div style={{
      height: '100vh', 
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
      }}>

      <h1 style={{
        width: '100%',
        textAlign: 'center',
        fontSize: '4em',
      }}
      >
        !!!
      </h1>
    </div>

  );
}

export default App;
